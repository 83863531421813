<template>
  <div>
    <ListTemplate>
      <template v-slot:title>
        <h1>Szkolenia</h1>
      </template>
      <template v-slot:header>
        <div class="d-flex justify-space-between">
          <v-btn
            small
            color="primary"
            @click="openCreateModal"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-switch
            v-if="isAdmin || hasPermissions(['archive.view'])"
            v-model="onlyArchived"
            class="mt-0"
            label="Zarchiwizowane"
            @change="fetchData"
          />
        </div>
      </template>
      <template v-slot:content>
        <CoursesList
          :data="data"
          :loading="loading"
          :pagination="pagination"
          @fetchData="fetchData"
          @remove="setCandidateToRemove"
          @edit="setCourseToEdit"
          @rollbackStatus="rollbackStatus"
          @rowClick="openCourseModal"
          @download="setDownloadLoading"
        />
      </template>
    </ListTemplate>
    <CourseForm
      v-if="courseId"
      :courseId="courseId"
      @close="courseId = null"
      @refreshList="fetchData"
    />
    <ShowCourse
      v-if="showCourseId"
      :courseId="showCourseId"
      @close="closeShowModal"
    />
    <ConfirmationDialog
      v-if="courseToRemove"
      :value="!!courseToRemove"
      :loading="courseRemoveLoading"
      :text='`Usunąć kurs "${courseToRemove.name}" ?`'
      @confirm="deleteCourse"
      @deny="courseToRemove = null"
    />
  </div>
</template>

<script>
import {notify, notifyStandardError} from "@/services/notificationService";
import CoursesList from "@/components/courses/CoursesList";
import {fetchCourses, removeCourse, rollbackCourseStatus} from "@/services/coursesService";
import ListTemplate from "@/templates/ListTemplate";
import CourseForm from "@/components/courses/CourseForm";
import ConfirmationDialog from "@/components/system/ConfirmationDialog";
import ShowCourse from "@/components/courses/ShowCourse";
import permissions from "@/mixins/permissions";

export default {
  name: 'Courses',
  components: {ShowCourse, ConfirmationDialog, CourseForm, ListTemplate, CoursesList},
  data: () => ({
    data: [],
    loading: false,
    pagination: {
      perPage: 10,
      totalElements: 0,
      page: 1
    },
    courseId: null,

    showCourseId: null,

    courseToRemove: null,
    courseRemoveLoading: false,

    onlyArchived: false
  }),
  beforeMount() {
    // this.fetchData()
  },
  mixins: [permissions],
  methods: {
    closeShowModal(refreshList) {
      this.showCourseId = null
      if (refreshList) {
        this.fetchData()
      }
    },
    setDownloadLoading(id, isDownloading) {
      this.data = this.data.map(item => item.id === id ? {...item, isDownloading} : {...item})
    },
    openCourseModal({id}) {
      this.showCourseId = id
    },
    async rollbackStatus(id) {
      try {
        this.loading = true
        await rollbackCourseStatus(id)
        await this.fetchData()
      } catch (e) {
        notifyStandardError()
      } finally {
        this.loading = false
      }
    },
    setCandidateToRemove(id) {
      this.courseToRemove = this.data.find(item => item.id === id)
    },
    setCourseToEdit(id) {
      this.courseId = id
    },
    async fetchData() {
      try {
        this.loading = true
        const {data, totalElements} = await fetchCourses(this.onlyArchived, this.pagination)
        this.data = data
        this.pagination.totalElements = totalElements
      } catch (e) {
        notifyStandardError()
      } finally {
        this.loading = false
      }
    },
    async deleteCourse() {
      try {
        this.courseRemoveLoading = true
        await removeCourse(this.courseToRemove.id)
        this.courseToRemove = null
        notify('success', 'Szkolenia zostało usunięte')
        await this.fetchData()
      } catch (e) {
        notifyStandardError()
      } finally {
        this.courseRemoveLoading = false
      }
    },
    openCreateModal() {
      this.courseId = 'create'
    }
  }
}
</script>

<style scoped>

</style>
