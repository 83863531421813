<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="7" class="d-flex justify-center align-center">
            <h2>{{ title }}</h2>
          </v-col>
          <v-col cols="12" sm="5" class="d-flex justify-center align-center">
            <v-img
              :src="imgSrc"
              contain
              max-height="70"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ImageCard',
  props: {
    title: String,
    imgSrc: String
  }
}
</script>

<style scoped>

</style>
