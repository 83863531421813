<template>
  <div>
    <v-container>
      <v-row cols="12">
        <v-col cols="12">
          <span>Kierownicy:</span>
        </v-col>
      </v-row>
      <v-row v-if="managers.length">
        <v-col
          cols="12"
          v-for="manager in managers"
          :key="manager.id"
        >
          <ImageCard
            :title="`${manager.first_name} ${manager.last_name} `"
            :imgSrc="getImageById(manager.id)"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <NoData />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <span>Uczestnicy:</span>
        </v-col>
      </v-row>
      <v-row v-if="listeners.length">
        <v-col
          cols="12"
          v-for="listener in listeners"
          :key="listener.id"
        >
          <ImageCard
            :title="`${listener.first_name} ${listener.last_name} `"
            :imgSrc="getImageById(listener.id)"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <NoData />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ImageCard from "@/components/ImageCard";
import {fetchAttendeeSign} from "@/services/coursesService";
import NoData from "@/components/NoData";
import {notify} from "@/services/notificationService";
import {toBase64} from "@/utils/file";

export default {
  name: 'AttendeesList',
  components: {NoData, ImageCard},
  props: {
    attendees: Array
  },
  data: () => ({
    images: []
  }),
  computed: {
    managers() {
      return this.attendees.filter(attendee => attendee.is_manager)
    },
    listeners() {
      return this.attendees.filter(attendee => !attendee.is_manager)
    }
  },
  watch: {
    attendees() {
      this.fetchImages()
    }
  },
  beforeMount() {
    this.fetchImages()
  },
  methods: {
    getImageById(id) {
      const candidate = this.images.find(item => item.id === id)
      return candidate?.src
    },
    async fetchImages() {
      if (this.attendees?.length) {
        for (let i = 0; i < this.attendees.length; i++) {
          const attendee = this.attendees[i]
          try {
            const {data} = await fetchAttendeeSign(attendee.path)
            this.images.push({
              id: attendee.id,
              src: await toBase64(data)
            })
          } catch (e) {
            notify('error', `Nie udało się pobrać podpisu ${attendee.first_name} ${attendee.last_name}`)
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
