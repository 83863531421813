import {fetchUserRoles, fetchUsers} from "@/services/usersService";

export const fetchCoaches = async () => {
  const roles = await fetchUserRoles()
  const trainerRole = roles.find(item => item.name.toLowerCase() === 'trainer')
  
  const {data} = await fetchUsers({perPage: 1000000})
  return data
    .filter(user => user.role.id === trainerRole.id)
    .map(({id, firstName, lastName}) => ({id, name: `${firstName} ${lastName}`}))
}


